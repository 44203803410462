import { default as _91slug_933t2OGz1k7eMeta } from "/home/evelle-2023/pages/[slug].vue?macro=true";
import { default as indexEZU6VMnUUMMeta } from "/home/evelle-2023/pages/index.vue?macro=true";
import { default as login24cOOza8jHAMeta } from "/home/evelle-2023/pages/login2.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/evelle-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/evelle-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login2",
    path: "/login2",
    component: () => import("/home/evelle-2023/pages/login2.vue").then(m => m.default || m)
  }
]