import revive_payload_client_4sVQNw7RlN from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/evelle-2023/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/evelle-2023/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/evelle-2023/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/evelle-2023/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/evelle-2023/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import fontawesome_klhsrycjcK from "/home/evelle-2023/plugins/fontawesome.js";
import pageproperty_uWRlp5wpNj from "/home/evelle-2023/plugins/pageproperty.ts";
import webmessages_G2GzqOuL9y from "/home/evelle-2023/plugins/webmessages.ts";
import pageLayout_55a3bqV7FA from "/home/evelle-2023/plugins/pageLayout.ts";
import seoObjCreator_JHwjYTd7g4 from "/home/evelle-2023/plugins/seoObjCreator.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  fontawesome_klhsrycjcK,
  pageproperty_uWRlp5wpNj,
  webmessages_G2GzqOuL9y,
  pageLayout_55a3bqV7FA,
  seoObjCreator_JHwjYTd7g4
]