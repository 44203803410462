import type { MetaData } from '~/types/backendtypes'
import type {UseSeoMetaInput} from '@unhead/vue'
export default defineNuxtPlugin((nuxtApp) => {

    const router = useRouter();
    const currentRoute = router.currentRoute;
    const config = useRuntimeConfig();

    return {
        provide: {
            seoObjectCreator: (seoInput: MetaData | undefined): UseSeoMetaInput =>{
                let seoTitle;
                if(seoInput?.title){
                    seoTitle = seoInput.title;
                }else if(seoInput?.name){
                    seoTitle = seoInput.name;
                }else{
                    seoTitle = ''
                }

                return {
                        title: seoTitle,
                        description: seoInput?.description?seoInput.description:'Boost your skin, hair and nails',
                        keywords: seoInput?.keywords?seoInput.keywords:'',
                        ogTitle: seoTitle,
                        ogType: 'website',
                        ogImage: config.public.API_BASE + '/CKFinderJava/userfiles/images/evelle/evelleNuxt/evelle_og_image.jpg',
                        ogUrl: currentRoute.value.fullPath,
                        ogDescription: seoInput?.description?seoInput.description:'Boost your skin, hair and nails',
                        twitterTitle: seoTitle,
                        twitterDescription: seoInput?.description?seoInput.description:'Boost your skin, hair and nails',
                        twitterImage: config.public.API_BASE + '/CKFinderJava/userfiles/images/evelle/evelleNuxt/evelle_og_image.jpg',
                        twitterCard: 'summary'
                    }
            }
        }
    }
})