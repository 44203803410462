import type { LayoutKey } from "#build/types/layouts";

export default defineNuxtPlugin((nuxtApp)=>{
    return {
        provide: {
            pageLayout: async (layout: string | undefined): Promise<LayoutKey | undefined> => {
                if(layout) {
                    console.log("PageLayout", layout)
                    return layout.replace('.jsp', '').toLowerCase() as LayoutKey;
                } else {
                    console.error("No layout was defined");
                }
                
            }
        }
    }
})